import Vue from 'vue'

import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {
  "customProperties": true,
  "iconfont": "mdi",
  "theme": {
    "primary": "#0c7894",
    "accent": "#ededed",
    "secondary": "#ff8f00",
    "info": "#26a69a",
    "warning": "#ffc107",
    "error": "#dd2c00",
    "success": "#00e676"
  }
})
