const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    siteId: '5e74af48ee79e7e1f2774fc4',
    disabledComponents: ['wButtonLike'],
    enableExperimentalBlocks: true,
    showCardDescription: true,
    authMode: [
      [
        'local',
        {
          mode: 'unique_password',
          defaultLogin: 'digital+haropa@mediameeting.fr'
        }
      ]
    ],
    metadatas: {
      name: `Port d'attache`,
      colorPrimary: '#0c7894'
    },
    analytics: {
      ga: 'UA-111736601-32',
      mixpanel: '8f0008079241b0530b2464b85a58c25c'
    },
    pages: (pager) => {
      return pager
        .remove('radios')
        .remove('podcasts')
        .update('default', (page) => {
          page.entry.title = 'Podcasts'
          page.entry.visibleInMenu = false
          page.setOptions({
            payload: {
              is: 'podcast'
            }
          })

          return page
        })
    }
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
